import { Reference } from "../reference-interface"
import { pitsInfoCards } from "../../service-info-card-interface/used_by/play-it-store-cards"
import { playitInfoCards } from "../../service-info-card-interface/used_by/play-it-cards"

export const PlayIt : Reference = {
  monitorPic: "/images/refs/playit/playit_monitor.png",
  siteName: "Playit",
  siteDescription: "2021 nyarán a magyar piacra lépett a Playit weboldalának innovációja, amit a teljeskörű háttérfejlesztő szolgáltstással hoztunk létre a márka átfogó képének megalkotásához ",
  siteUrl: "https://www.playit.hu/",
  cards: playitInfoCards,
  referencePics: [
    "/images/refs/playit/playit_ref.png",
    "/images/refs/playit/playit_ref_color.png",
    "/images/refs/playit/playit_3.png",
  ],
  pageFunctions: [
    "Egyedi design",
    "Önálló adminfelület",
  ],
  reversePageFunctions: false,
}
