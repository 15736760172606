import { ServiceInfoCardChildInterface } from "../service-info-card-interface"

const playit1 : ServiceInfoCardChildInterface = {
  bigImg: false,
  description: "A piaci pozíciójukat megerősítve, valamint további növekedési, fejlődési terveiket kiegészítve készítettük el modern honlapjukat. Olyan webes felületet képzeltek el, ami személyre szabottan tükrözi az általuk képviselt minőséget, és könnyen bővülő portfóliójukhoz igazítható.",
  displayXo: true,
  reverse: true,
  title: "Ismert név, új szereplő",
  picture: "/images/refs/playit/playit_1.png",
  boldDescription: ''
}

const playit2 : ServiceInfoCardChildInterface = {
  bigImg: false,
  description: "A látogatóiknak mobil eszközről is könnyen navigálhatóvá vált a felületen. A weboldalon keresztüli megkeresések nyomon követése egyszerűbb, illetve könnyen, bárhonnan kezelhető admin felületen aktualizálni tudják a változásokat",
  displayXo: true,
  reverse: false,
  title: "Reszponzív élmény",
  picture: "/images/refs/playit/playit_2.png",
  boldDescription: ''
}

export const playitInfoCards = [
  playit1,
  playit2,
]

